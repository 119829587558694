import { Injectable } from '@angular/core';
import { environment } from '../environment/environment';
import { HttpClient,HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SimulationModuleService {

  constructor(public http: HttpClient) { }

  ArchivedAvailableBalance(key, userId){
    const url = environment.url_sandbox + '/Fgts/ArchivedAvailableBalance?key=' + key + '&userId=' + userId;
    let _return = this.http.put(url,{})
    return _return;
  }

  availableBalance(body: any){
      const url = environment.url_sandbox + '/Fgts/AvailableBalance';
      let _return = this.http.post(url, body)
      return _return;
  }​

  DisbursementBankAccount(body: any){
    const url = environment.url_sandbox + '/FgtsProposal/DisbursementBankAccount';
    let _return = this.http.post(url, body)
    return _return;
  }

  GetAvaibleBalance(userId: any,key: any){
    const url = environment.url_sandbox + '/Fgts/GetAvailableBalance?userId=' + userId + '&key=' + key;
    let _return = this.http.get(url)
    return _return;
  }

  CountAvailableBalance(userId: any){
    const url = environment.url_sandbox + '/Fgts/CountAvailableBalance?userId=' + userId;
    let _return = this.http.get(url)
    return _return;
  }

  Historic(userId: any, documentNumber: any, dateBegin: any, dateEnd: any, simulation: any, proposal: any) {
    const url = `${environment.url_sandbox}/FGTS/history?userId=${userId}&individualDocumentNumber=${documentNumber}&dateBegin=${dateBegin}&dateEnd=${dateEnd}&simulations=${simulation}&proposals=${proposal}`;
    return this.http.get(url);
}

  FindAvaibleBalance(userId: any, typeCode){
    const url = environment.url_sandbox + '/Fgts/FindAvailableBalance?userId=' + userId + '&typeCode=' + typeCode;
    let _return = this.http.get(url)
    return _return;
  }
  
  SimulationDesiredValue(body: any){
    const url = environment.url_sandbox + '/FgtsSimulation/SimulationDesiredValue';
    let _return = this.http.post(url, body)
    return _return;
  }

  getUserByKey(filter: any){
    const url = environment.url_sandbox + '/available_balance/' + filter;
    let _return = this.http.get(url)
    return _return;
  }

  desiredValueSimulation(body: any){
      const url = environment.url_sandbox + '/baas/fgts_simulation_guess';
      return this.http.post(url, body);
  }
  
  createProposal(body: any){
    const url = environment.url_sandbox + '/FgtsProposal/CreateProposal';
    return this.http.post(url, body);
  }

  cancelProposal(body: any){
    const url = environment.url_sandbox + '/FgtsProposal/CancelProposal';
    return this.http.post(url, body);
  }

  getProposal(UserId: any, document_number: any){
      const url = environment.url_sandbox + '/FgtsProposal/GetProposal?userId=' + UserId + '&key=' + document_number;
      return this.http.get(url);
  }

  findProposalFiltred(UserId, documentNumber, status, dateBegin, dateEnd) {
    const url = environment.url_sandbox + '/FgtsProposal/FindProposal?userId=' + UserId + '&individualDocumentNumber=' + documentNumber + '&status=' + status + "&dateBegin=" + dateBegin + "&dateEnd=" + dateEnd;
    return this.http.get(url);
  }

  findProposal(UserId: any){
    const url = environment.url_sandbox + '/FgtsProposal/FindProposal?userId=' + UserId;
    return this.http.get(url);
  }

  findProposalByDocumentNumber(UserId: any, document_number: any){
    const url = environment.url_sandbox + '/FgtsProposal/FindProposalByDocumentNumber?userId=' + UserId + '&documentNumber=' + document_number;
    return this.http.get(url);
  }

  UpdateStatus(body: any){
    const url = environment.url_sandbox + '/FgtsProposal/UpdateStatus';
    return this.http.put(url, body);
  }

  UpdateStatusAvailableBalance(body: any){
    const url = environment.url_sandbox + '/StatusAvailableBalance/UpdateStatusAvailableBalance';
    return this.http.put(url, body);
  }

  FindOperator(){
    const url = environment.url_sandbox + '/StatusAvailableBalance/FindOperator';
    return this.http.get(url);
  }

  desiredMaxValueSimulation(body: any){
    const url = environment.url_sandbox + '/baas/fgts_simulation';
    return this.http.post(url, body);
}


  getUser(userId: any){
    const url = environment.url_sandbox + '/User/Get?userId=' + userId;
    return this.http.get(url);
  }

  bankList(){
    const url = "https://brasilapi.com.br/api/banks/v1"
    return this.http.get(url)
  }

}
