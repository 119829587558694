import { Component, HostListener } from '@angular/core';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MonitoringResumeComponent } from '../fgts-monitoring/monitoring-resume/monitoring-resume.component';
import { SimulationModuleService } from '../services/simulation-module.service';
import { AppSetting } from '../entities/utils/appsettings';
import { AreYourWantToDeleteComponent } from './are-your-want-to-delete/are-your-want-to-delete.component';
import { ChangeDateRepresentationComponent } from './change-date-representation/change-date-representation.component';
import { Router } from '@angular/router';
import { SnackBarMessageService } from '../services/snack-bar.service';
import { CorService } from '../services/color.service';
import {Clipboard} from '@angular/cdk/clipboard';
import { ProposalStatus } from '../services/proposalStatus.service';
import { Format } from '../services/format.service';

@Injectable({
  providedIn: 'root'
})
@Component({
  selector: 'app-fgts-monitoring',
  templateUrl: './fgts-monitoring.component.html',
  styleUrls: ['./fgts-monitoring.component.css'],
  providers: [
    SnackBarMessageService
  ]
})
export class FgtsMonitoringComponent {
  
  public appSetting = new AppSetting()
  
  constructor(
    public format: Format,
    public snackBarMessageService: SnackBarMessageService,
    public router: Router,
    public simulation_service: SimulationModuleService,
    public dialog: MatDialog,
    private corService: CorService,
    private clipboard: Clipboard,
    private proposalStatus: ProposalStatus
    ){
      this.colorPrimary = this.colorPrimary === '' ? '#4ECD72' : this.colorPrimary;
      this.colorSecond = this.colorSecond === '' ? '#FFF' : this.colorSecond;
      this.inicialDateSelected = this.getFirstDayOfCurrentMonth();
      this.finalDateSelected = this.getDateNow()
    }

  submit_click: boolean = false
  valueSearch: string = ""
  colorPrimary: string = this.corService.colorPrimary
  colorSecond: string = this.corService.colorSecond
  mainFontColor: string = this.corService.mainFontColor
  list_value: any = []
  proposalDetails: any = []
  Loadin_screen: boolean = false
  userId: string | null = localStorage.getItem("userId")
  isCopying: boolean = false;
  proposalStatusName = this.proposalStatus.status
  valueSearchStatusProposal: any = "All"
  statusChoicedForSearch: any = []
  pf: any
  homelog: boolean = false
  iduser = localStorage.getItem("userId")
  inicialDateSelected: any = undefined
  finalDateSelected: any = undefined
  loadingProposals: boolean = true
  loadingButtonProposals = true
  isMobileView: boolean = false;

  sortField: string = ''; 
  sortAscending: boolean = true; 


  totalAmountToRecive

ngOnInit() {
  this.Loadin_screen = true
  this.checkUserId()
  this.checkScreenWidth();
  this.simulation_service.getUser(this.iduser).subscribe( (result: any) => {
    this.pf = result.salesPF
  })
  var urlAtual = window.location.href;
  console.log(urlAtual);
  if (urlAtual == "https://app-homolog.finantopay.com.br/monitoring") {
    this.homelog = true
  } else {
    this.homelog = false
  }

  this.loadingProposals = true
  let filterStatus
  let cpf = this.valueSearch
  const cpfFormatado = this.removerCaracteresEspeciaisCPF(cpf)

  if (this.valueSearchStatusProposal == 'All') {
    filterStatus = ""
  } else {
    filterStatus = this.valueSearchStatusProposal
  }
  
  this.simulation_service.findProposalFiltred(
    this.userId, 
    cpfFormatado,
    filterStatus, 
    this.formatDateToISOWithTimeInicial(this.inicialDateSelected), 
    this.formatDateToISOWithTimeFinal(this.finalDateSelected)).subscribe( (scc:any) => {
      this.list_value = scc
      this.totalAmountToRecive = scc.reduce((acc, item) => {
        if (item.status !== 'canceled' && item.status !== 'canceled_permanently') {
            return acc + item.amount_to_receive;
        }
        return acc;
      }, 0);
    this.Loadin_screen = false
    this.loadingProposals = false
  },err => {
    this.Loadin_screen = false
    this.loadingProposals = false
    console.log(err)
  })
}

@HostListener('window:resize', ['$event'])
onResize(event: any) {
  this.checkScreenWidth();
}

checkScreenWidth() {
  this.isMobileView = window.innerWidth <= 850;
}

checkUserId() {
  if (!this.userId || this.userId == null) {
    this.router.navigate(['error']);
  }
}

 converterData(dataIso: string): string {
  const data = new Date(dataIso);
  const ano = data.getFullYear();
  const mes = (data.getMonth() + 1).toString().padStart(2, '0');
  const dia = data.getDate().toString().padStart(2, '0');
  const hora = data.getHours().toString().padStart(2, '0');
  const minutos = data.getMinutes().toString().padStart(2, '0');
  const segundos = data.getSeconds().toString().padStart(2, '0');
  return `${dia}/${mes}/${ano} ${hora}:${minutos}:${segundos}`;
}

FindProposal() {
  this.loadingButtonProposals = false
  this.loadingProposals = true
  let filterStatus
  let cpf = this.valueSearch
  const cpfFormatado = this.removerCaracteresEspeciaisCPF(cpf)

  if (this.valueSearchStatusProposal == 'All') {
    filterStatus = ""
  } else {
    filterStatus = this.valueSearchStatusProposal
  }

  this.compareDates()

  this.simulation_service.findProposalFiltred(
    this.userId, 
    cpfFormatado,
    filterStatus, 
    this.formatDateToISOWithTimeInicial(this.inicialDateSelected), 
    this.formatDateToISOWithTimeFinal(this.finalDateSelected)).subscribe( (scc:any) => {
    this.list_value = scc
    console.log(scc)
    this.totalAmountToRecive = scc.reduce((acc, item) => {
      if (item.status !== 'canceled' && item.status !== 'canceled_permanently') {
          return acc + item.amount_to_receive;
      }
      return acc;
    }, 0);
    this.Loadin_screen = false
    this.loadingProposals = false
    setTimeout(() => {
      this.loadingButtonProposals = true
    }, 10000);
  },err => {
    this.Loadin_screen = false
    this.loadingProposals = false
    console.log(err)
  })
}

compareDates() {
const inicialDate = new Date(this.inicialDateSelected);
const finalDate = new Date(this.finalDateSelected);

if (inicialDate > finalDate) {
    this.snackBarMessageService.error('Data inicial é maior que a data final')
  }
}

getDateNow(): string {
  const today = new Date();
  const thirtyDaysAgo = new Date(today.getTime());
  const year = thirtyDaysAgo.getFullYear();
  let month: string | number = thirtyDaysAgo.getMonth() + 1;
  let day: string | number = thirtyDaysAgo.getDate();
  month = month < 10 ? '0' + month : month;
  day = day < 10 ? '0' + day : day;

  return `${year}-${month}-${day}`;
}

getFirstDayOfCurrentMonth(): string {
  const today = new Date();
  const year = today.getFullYear();
  let month: string | number = today.getMonth() + 1;
  const day = '01'; 

  month = month < 10 ? '0' + month : month;

  return `${year}-${month}-${day}`;
}

formatDateToISOWithTimeInicial(date: string): string {
  const dateObj = new Date(date);
  return dateObj.toISOString().split('T')[0] + 'T00:00:00Z'; 
}

formatDateToISOWithTimeFinal(date: string): string {
  const dateObj = new Date(date);
  return dateObj.toISOString().split('T')[0] + 'T23:59:59Z'; 
}

handleCancelProposal(key: string) {
  const dialogRef = this.dialog.open(AreYourWantToDeleteComponent, {
    width: '600px',
    height: '300px',
    data: key
  });
}

reapresentation(data: string) {
  const dialogRef = this.dialog.open(ChangeDateRepresentationComponent, {
    width: '600px',
    height: '360px',
    data: data
  });
}

async openDetalhes(key: any, status: any) {
  console.log( key, status, 'key')
  this.submit_click = true
  this.checkUserId()
  await this.simulation_service.getProposal(this.userId, key).subscribe((scc: any) => {
    this.proposalDetails = scc;
    this.submit_click = false

    const dialogRef = this.dialog.open(MonitoringResumeComponent, {
      width: '1400px',
      height: '95%',
      data: this.proposalDetails
    });
  }, (err) => {
    this.submit_click = false
    this.snackBarMessageService.error(err.error.messages[0].text)
    console.log(err)
  })
}

copyLink(urlProvider: string, urlSign: string){
  if (urlProvider == "" || urlProvider == null) {
    this.clipboard.copy(urlSign)
    this.snackBarMessageService.success("Link Copiado Com Sucesso");
  } else {
    this.clipboard.copy(urlProvider);
    this.snackBarMessageService.success("Link Copiado Com Sucesso");
  }
}

copyPhone(AreaCode, number: any) {
  try{
    let value = `${AreaCode} ${number}`
    this.isCopying = true;
    this.clipboard.copy(value);
    console.log(value)
    this.isCopying = false
    this.snackBarMessageService.success("Valor copiado com sucesso !")
  }catch(e){
    this.isCopying = false
    this.snackBarMessageService.success("Erro ao copiar o valor!")
    console.log(e)
  }
}

copyValue(value: any) {
  try{
    this.isCopying = true;
    this.clipboard.copy(value);
    console.log(value)
    this.isCopying = false
    this.snackBarMessageService.success("Valor copiado com sucesso !")
  }catch(e){
    this.isCopying = false
    this.snackBarMessageService.success("Erro ao copiar o valor!")
    console.log(e)
  }
}

removerCaracteresEspeciaisCPF(cpf: any) {
  return cpf.replace(/[^0-9]/g, '');
}

sortData(field: string) {
  if (this.sortField === field) {
    this.sortAscending = !this.sortAscending;
  } else {
    this.sortAscending = true;
    this.sortField = field;
  }
  this.list_value.sort((a, b) => {
    let valueA, valueB;

    switch (field) {
      case 'name':
        valueA = a.borrower.name.toLowerCase();
        valueB = b.borrower.name.toLowerCase();
        break;
      case 'status':
        valueA = a.status.toLowerCase();
        valueB = b.status.toLowerCase();
        break;
      case 'amount_to_receive':
        valueA = a.amount_to_receive;
        valueB = b.amount_to_receive;
        break;
      case 'modification_date':
        valueA = new Date(a.event_datetime).getTime();
        valueB = new Date(b.event_datetime).getTime();
        break;
      case 'creation_date':
        valueA = new Date(a.creation_date).getTime();
        valueB = new Date(b.creation_date).getTime();
        break;
      default:
        return 0;
    }

    if (valueA < valueB) {
      return this.sortAscending ? -1 : 1;
    }
    if (valueA > valueB) {
      return this.sortAscending ? 1 : -1;
    }
    return 0;
  });
}
}
